import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    minHeight: 300,
    [theme.breakpoints.down('xs')]: {
      height: 400,
    },
  },

  iframe: {
    border: 0,
    borderRadius: 25,
    position: 'absolute',
  },
}));

const Map = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <iframe
        title="Micron location"
        src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d1925.1616548997804!2d51.39873888373717!3d35.75761086480298!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3f8e06e5513ad34d%3A0x1d3d7036fc856bf9!2sMasumi%2C%20Tehran%20Province%2C%20Tehran%2C%20Iran!3m2!1d35.7578244!2d51.3990606!5e0!3m2!1sen!2sde!4v1623074526333!5m2!1sen!2sde"
        width="100%"
        height="100%"
        className={classes.iframe}
        loading="lazy"
      ></iframe>
    </div>
  );
};

export default Map;
