import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';

import Template from '../components/Template';
import Metadata from '../components/Metadata';
import ContactForm from '../components/ContactForm';
import Address from '../components/Address';
import Map from '../components/Map';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    padding: 20,
    [theme.breakpoints.up('sm')]: {
      minHeight: 'calc(100vh - 78.7px)',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '100vh',
    },
  },

  mapWrapper: {
    flexGrow: 1,
    marginTop: 16,
    position: 'relative',
  },
}));

const Contact = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Template>
      <Metadata pageTitle={t('Menu.Contact')} />
      <Grid container direction="column" wrap="nowrap" className={classes.root}>
        <Grid item container spacing={2} justifyContent="space-between">
          <Grid item xs={12} sm={7} lg={6}>
            <Address />
          </Grid>
          <Grid item xs={12} sm={5} lg={6}>
            <ContactForm />
          </Grid>
        </Grid>

        <Grid item className={classes.mapWrapper}>
          <Map />
        </Grid>
      </Grid>
    </Template>
  );
};

export default Contact;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
