import React, { useRef, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from '@reach/router';

const useStyles = makeStyles({
  form: {
    '& > *': {
      width: '80%',
      marginBottom: 16,
      marginTop: 10,
    },
    height: '85%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
});

const ContactForm = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const form = useRef(null);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleChangeName = (event) => {
    setName(event.target.value);
  };
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleChangeSubject = (event) => {
    setSubject(event.target.value);
  };
  const handleChangeMessage = (event) => {
    setMessage(event.target.value);
  };

  return (
    <Paper elevation={0} style={{ height: 520 }}>
      <Typography variant="h5" gutterBottom>
        {t('Contact.Form.title')}
      </Typography>
      <Divider />
      <form
        ref={form}
        className={classes.form}
        method="POST"
        action={`https://formsubmit.co/${process.env.GATSBY_FORM_SEND_EMAIL}`}
      >
        <input
          type="hidden"
          name="_subject"
          value="Contact Us Form Submission"
        />
        <input type="hidden" name="_template" value="table" />
        {/* _honey: avoid spam by fooling scrapers */}
        <input type="text" name="_honey" style={{ display: 'none' }} />
        <input type="hidden" name="_url" value={location.href} />
        <TextField
          label={t('Contact.Form.name')}
          id="name"
          name="Name"
          value={name}
          onChange={handleChangeName}
          required
        />
        <TextField
          label={t('Contact.Form.email')}
          id="email"
          name="Email"
          type="email"
          value={email}
          onChange={handleChangeEmail}
          required
        />
        <TextField
          label={t('Contact.Form.subject')}
          id="subject"
          name="Subject"
          value={subject}
          onChange={handleChangeSubject}
        />
        <TextField
          label={t('Contact.Form.message')}
          id="message"
          name="Message"
          value={message}
          onChange={handleChangeMessage}
          required
          multiline
          rows={4}
        />
        <Button type="submit" variant="outlined">
          {t('‌Button.send')}
        </Button>
      </form>
    </Paper>
  );
};

export default ContactForm;
