import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Grid from '@material-ui/core/Grid';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';

const phones = ['Contact.Address.tel.no.1', 'Contact.Address.tel.no.2'];
const align = 'assets.css.side';

const Address = () => {
  const { t } = useTranslation();

  return (
    <Paper elevation={0} style={{ height: 520 }}>
      <Typography variant="h5" gutterBottom>
        {t('Contact.Address.title')}
      </Typography>
      <Divider />
      <Grid
        container
        direction="column"
        justifyContent="space-around"
        style={{ height: '85%' }}
      >
        <Grid item container>
          <Grid item>
            <LocationOnRoundedIcon htmlColor="#aaa" fontSize="large" />
          </Grid>
          <Grid item>
            <Typography variant="h6">
              {t('Contact.Address.headquarter.title')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              {t('Contact.Address.headquarter.addr')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item>
            <LocationOnRoundedIcon htmlColor="#aaa" fontSize="large" />
          </Grid>
          <Grid item>
            <Typography variant="h6">
              {t('Contact.Address.factory.title')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              {t('Contact.Address.factory.addr')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item>
            <PhoneRoundedIcon
              htmlColor="#aaa"
              fontSize="large"
              style={{ transform: t('assets.css.scaleX') }}
            />
          </Grid>
          <Grid item>
            <Typography variant="h6">
              {t('Contact.Address.tel.title')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {phones.map((phone) => (
              <Typography
                key={phone}
                variant="body2"
                align={t(align)}
                className="ltr"
              >
                <a href={`tel:${t(phone)}`}>{t(phone)}</a>
              </Typography>
            ))}
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item>
            <EmailRoundedIcon htmlColor="#aaa" fontSize="large" />
          </Grid>
          <Grid item>
            <Typography variant="h6">
              {t('Contact.Address.email.title')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" align={t(align)} className="ltr">
              <a href={`mailto:${t('Contact.Address.email.addr')}`}>
                {t('Contact.Address.email.addr')}
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Address;
